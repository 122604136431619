<template>
  <div>
    <!--begin::Row-->
    <!-- <div class="row">
        <div class="col-lg-12"> -->
            <div class="card card-custom card-stretch gutter-b">
                <!--begin::Header-->
                <div class="card-header border-0">
                    <h3 class="card-title font-weight-bolder text-dark">Rekapitulasi Nilai PPB KL</h3>
                    <div class="card-toolbar">
                        
                    </div>
                </div>
                <!--end::Header-->
                <!--begin::Body-->
                <div class="card-body pt-0">
                    <!--begin::Item-->
                    <div class="mb-12">
                        <!--begin::Content-->
                        <div v-if="this.user.id_role == 'supervisor_kl'">
                            <div style="overflow-x:auto;">
                                <table id="example" class="table table-striped table-bordered" style="border-collapse: collapse;width:100%">
                                  <thead>
                                      <tr>
                                          <th rowspan="2">No</th>
                                          <th rowspan="2">Nama KL</th>
                                          <th style="text-align:center;">Aktivitas Akun</th>
                                          <th style="text-align:center;">Verifikasi Dokumentatif</th>
                                          <th style="text-align:center;">Verifikasi Lapangan</th>
                                          <th style="text-align:center;">File BA</th>
                                      </tr>
                                      <tr>
                                        <th style="text-align:center;">PM PPB</th>
                                        <th style="text-align:center;">PM PPB</th>
                                        <th style="text-align:center;">PM PPB</th>
                                        <th style="text-align:center;">PM PPB</th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                      <tr v-for="row in totskor" :key="row.id">
                                          <td></td>
                                          <td>{{row.nama_kl}}</td>
                                          <td>
                                              <div v-if="row.login_ppb === 'Belum Login'">
                                                <b-badge class="mr-1" variant="danger">Belum Login</b-badge>
                                              </div>
                                              <div v-else-if="row.login_ppb === 'Sudah Login, Sudah Melakukan Penlaian'">
                                                <b-badge class="mr-1" variant="warning">Sudah Login, Sudah Melakukan Penlaian</b-badge>
                                              </div>
                                              <div v-else-if="row.login_ppb === 'Sudah Login, Belum Melakukan Penlaian'">
                                                <b-badge class="mr-1" variant="warning">Sudah Login, Belum Melakukan Penlaian</b-badge>
                                              </div>
                                              <div v-else>
                                                <b-badge class="mr-1" variant="success">Sudah Melakukan Penilaian</b-badge>
                                              </div>
                                          </td>
                                          <td>
                                              <div v-if="row.login_verif_dok_ppb === 'Belum'">
                                                <b-badge class="mr-1" variant="danger">Belum</b-badge>
                                              </div>
                                              <div v-else-if="row.login_verif_dok_ppb === 'Sebagian'">
                                                <b-badge class="mr-1" variant="warning">Sebagian</b-badge>
                                              </div>
                                              <div v-else>
                                                <b-badge class="mr-1" variant="success">Sudah</b-badge>
                                              </div>
                                          </td>
                                          <td>
                                              <div v-if="row.login_verif_lap_ppb === 'Belum'">
                                                <b-badge class="mr-1" variant="danger">Belum</b-badge>
                                              </div>
                                              <div v-else-if="row.login_verif_lap_ppb === 'Sebagian'">
                                                <b-badge class="mr-1" variant="warning">Sebagian</b-badge>
                                              </div>
                                              <div v-else>
                                                <b-badge class="mr-1" variant="success">Sudah</b-badge>
                                              </div>
                                          </td>
                                          <td style="text-align:center">
                                            <div style="width:120px" v-if="row.file_ba_ppb == null || row.file_ba_ppb === ''">
                                                
                                            </div>
                                            <div v-else>
                                                <a :href="row.file_ba_ppb" target="_blank" class="btn btn-text-dark-50 btn-icon-success font-weight-bold btn-hover-bg-light mr-3 btn-lg"><i class="fa fa-download" aria-hidden="true"/></a>
                                            </div>
                                          </td>
                                      </tr>
                                  </tbody>
                              </table>
                            </div>
                        </div>
                        <div v-else>
                          Maaf Anda tidak dapat mengakses halaman ini!
                        </div>
                        <!--end::Content-->
                    </div>
                    <!--end::Item-->
                </div>
                <!--end: Card Body-->
            </div>
        </div>
    <!-- </div>
  </div> -->
</template>
<style>
  div.messages {
  /* background-color: lightblue; */
  /* width: 110px; */
  height: 500px;
  overflow: auto;
}
</style>
<style lang="">
  
</style>
<script src="https://code.jquery.com/jquery-3.5.1.js"></script>
<script src="https://cdn.datatables.net/1.10.24/js/jquery.dataTables.min.js"></script>
<script src="https://cdn.datatables.net/buttons/1.7.0/js/dataTables.buttons.min.js"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.53/pdfmake.min.js"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.53/vfs_fonts.js"></script>
<script src="https://cdn.datatables.net/buttons/1.7.0/js/buttons.print.min.js"></script>
<script src="https://cdn.datatables.net/buttons/1.7.0/js/buttons.html5.min.js"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/jszip/3.1.3/jszip.min.js"></script>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import axios from 'axios';
export default {
  data() {
      return {
        test:'hai',
        url: localStorage.getItem('baseapi'),
        token: localStorage.getItem('id_token'),
        totskor:[],
        user: JSON.parse(localStorage.getItem('user')),
      }
  },
  components: {
    
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Rekapitulasi" }]);
    this.loaddata()
  },
  methods: {
    loaddata(){
      if (this.user.id_role === 'super_user' || this.user.id_role === 'tim_penilai' || this.user.id_role === 'operator_nomine' || this.user.id_role === 'qc' || this.user.id_role === 'tim_teknis_penilai' || this.user.id_role === 'dpb' || this.user.id_role === 'kemenkeu' || this.user.id_role === 'expert' || this.user.id_role === 'supervisor_provinsi'|| this.user.id_role === 'supervisor_kl') {
        axios.get(this.url+"/rekapitulasi_kl?tahun=2024", {
          headers: {
              'xth': this.token
          }
        })
        .then(response => {
            this.totskor = response.data;
            this.initDatatable();
        }).catch(error=>{
            return error
            });    
      } else {
        this.$router.push({ name: "dashboard" })
      }   
    },
    initDatatable() {
        setTimeout(() => {
        var t =  $('#example').DataTable({
            "pagingType": "full_numbers",
            "language": {
                "info": "Menampilkan _START_ sampai _END_ dari _TOTAL_ data",
                "paginate": {
                    "first":      "Awal",
                    "last":       "Akhir",
                    "next":       "Selanjutnya",
                    "previous":   "Sebelumnya"
                },
            },
            scrollX: true,
            fixedColumns:   {
                left: 2,
            },
            dom: 'Bfrtip',
            "fnRowCallback": function(nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                //debugger;
                var index = iDisplayIndexFull + 1;
                $("td:first", nRow).html(index);
                return nRow;
              },
              columnDefs: [{
                targets: 0,
                render: function(data, type, row, meta) {
                console.log(meta.row);
                console.log(type == 'export'? meta.row : data);
                  return type == 'export'? meta.row + 1 : data;
                }
            }],
            buttons: [
                {
                  extend: 'excel',
                    text : 'Excel',
                    title: 'REKAPITULASI PENILAIAN KL KEMENTERIAN INVESTASI/BKPM',
                    exportOptions: {
                        columns: [ 0, 1, 2, 3, 4, 5],
                        orthogonal: 'export',
                        format: {
                            header: function ( data, columnIdx ) {
                                if(columnIdx==2){
                                return 'Aktivitas ' + data;
                                }
                                else if(columnIdx==3){
                                  return 'Verifikasi Dokumentatif ' + data;
                                }
                                else if(columnIdx==4){
                                  return 'Verifikasi Lapangan ' + data;
                                }
                                else if(columnIdx==5){
                                  return 'File BA ' + data;
                                }
                                
                                else{
                                  return data;
                                }
                            },
                            body: function(data, row, column, node) {
                                data = $('<p>' + data + '</p>').text();
                                return data.replace(',', '.');
                            },
                        }
                    },
                    filename: function(){
                        var today = new Date();
                        // var n = d.getTime();
                        var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
                        
                        return 'Rekapitulasi Nilai PPB KL ' + date;
                    },
                },
                {
                  extend: 'pdf',
                    text : 'PDF',
                    title: 'REKAPITULASI PENILAIAN KL KEMENTERIAN INVESTASI/BKPM',
                    exportOptions: {
                        columns: [ 0, 1, 2, 3, 4, 5, 6],
                        orthogonal: 'export',
                    },
                    filename: function(){
                        var today = new Date();
                        // var n = d.getTime();
                        var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
                        
                        return 'Rekapitulasi Nilai PPB KL ' + date;
                    },
                }
            ],
            "lengthMenu": [
                [10, 25, 50, -1],
                [10, 25, 50, "All"]
            ],
            order: [[3, 'desc']],
            responsive: true,
            destroy: true,
            retrieve: true,
            autoFill: true,
            colReorder: true,

        });
        t.on( 'order.dt search.dt', function () {
        t.column(0, {search:'applied', order:'applied'}).nodes().each( function (cell, i) {
            cell.innerHTML = i+1;
        } );
        } ).draw();
        }, 300)
    },
    format_desimal(value){
         if (value) {
           return value.replace(".",",");
          }
    },
    urlppb(kode_wilayah){
      let routeData = this.$router.resolve(
        {
          path: `/rekapitulasi/detailppbkl/${kode_wilayah}`, 
        });
        window.open(routeData.href, '_blank');
    },
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    }
  },
  created(){
    //   this.getuser(this.user)
    // this.getlocaldata()
  }
};
</script>
